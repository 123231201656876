import React, { useEffect, useState } from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Hello from "./components/Hello";
import Navbar from "./components/Navbar";
import Skillset from "./components/Skillset";
import { motion } from "framer-motion";
import './index.css'
import { addDoc, collection, GeoPoint } from "firebase/firestore";
import { db } from "./firebase-config";
import Projects from "./components/Projects";


function App() {

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  });

  const variants = {
    default: {
      x: mousePosition.x - 2,
      y: mousePosition.y - 2,
    }
  }

  const subscriberRef = collection(db, "stalkers");


  const [locationaccess, setlocationaccess] = useState(false)
  const [latitude, setLatitude] = useState(1.1)
  const [longitude, setLongitude] = useState(1.1)
  const [city, setCity] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [countryName, setCountryName] = useState("")
  const [state, setState] = useState("")



  const setLocations = () => {
    if (!locationaccess) {
      fetch("https://geolocation-db.com/json/")
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setLatitude(data.latitude)
          setLongitude(data.longitude)
          setCity(data.city)
          setCountryCode(data.country_code)
          setCountryName(data.country_name)
          setState(data.state)
        })
    }
    setlocationaccess(true)
  }


  useEffect(() => {
    setLocations();
    addFire();

    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      });
    }

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    }

  }, [latitude, longitude, city, state, countryName, countryCode])

  async function addFire() {
    // const d = new Date();
    // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    // let expires = "expires=" + d.toUTCString();
    // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

    if (latitude == 1.1 && longitude == 1.1) {

    } else {
      await addDoc(subscriberRef, {
        location: new GeoPoint(latitude, longitude),
        date: " " + String(new Date(Date.now())) + " ",
        new: true,
        city: city,
        state: state,
        countryName: countryName,
        countryCode: countryCode,
      });
    }

  };






  return (
    <div>
      <Navbar />
      <Hello />
      <About />
      <Skillset />
      <Projects />
      <Contact />
      <motion.div
        className="hidden cursor md:flex"
        variants={variants}
        animate="default"
      />
    </div>
  );
}

export default App;
