import React, { useEffect } from 'react'
import projectsText from '../assets/projectsText.svg'
import projectsmoji from '../assets/projectsmoji.svg'
import cloudit from '../assets/Cloudit.svg'
import buildspace from '../assets/buildspace.svg'
import cointrack from '../assets/cointrack.svg'
import weather from '../assets/weather.svg'
import mercedes from '../assets/MercedesTms.svg'
import metaexpo from '../assets/metaexpo.svg'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import ProjectCard from './ProjectCard'

const Projects = () => {

    const [ref, inView] = useInView({ triggerOnce: true, });
    const [ref2, inView2] = useInView({ triggerOnce: true, rootMargin: '-300px 0px 0px 0px' });
    const animation = useAnimation();
    const animation2 = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1, transition: { duration: 2 }
            })
        }
        if (!inView) {
            animation.start({
                opacity: 0
            })
        }
    }, [inView]);

    useEffect(() => {
        if (inView2) {
            animation2.start({
                opacity: 1, transition: { duration: 2, delay: 0.5 }
            })
        }
        if (!inView2) {
            animation2.start({
                opacity: 0
            })
        }
    }, [inView2]);

    return (
        <div name="projects" className='w-full bg-[#121316] px-4 text-gray-200 pb-40'>
            <div className='w-full max-w-6xl mx-auto'>
                <div className='relative w-full'>
                    <motion.div ref={ref} animate={animation}>
                        <img src={projectsText} alt='skill-text' className='mx-auto' />
                        <div className='absolute w-full top-4 sm:top-8 md:top-12 lg:top-20'>
                            <img src={projectsmoji} alt='skill-moji' className='mx-auto w-60 sm:w-72 md:w-80' />
                        </div>
                    </motion.div>
                    <motion.div ref={ref2} animate={animation2} className='grid w-full gap-8 pt-52 md:grid-cols-2 lg:grid-cols-3'>
                        <ProjectCard img={mercedes} skills={['ReactJS', 'NodeJS', 'MongoDB', 'TailwindCSS']} name="MercedesTMS" desc="MercedesTMS is a Tyre management system for F1 race weekends. Where you can add, allocate, manage race sessions and Tyre sets." link="https://merecedestms.netlify.app/" />
                        <ProjectCard img={metaexpo} skills={['NextJS', 'Framer motion', 'TailwindCSS']} name="Metaexpo" desc="Metaexpo is a contemporary metaverse-themed website designed to practice animation abilities utilising the NextJS framework." link="https://metaexpo.vercel.app/" />
                        <ProjectCard img={buildspace} skills={['ReactJS', 'TailwindCSS']} name="Buildspace" desc="Buildspace is a SaaS landing page that I developed to experiment with react components, responsive design, and new libraries." link="https://buildspacecompany.netlify.app/" />
                        <ProjectCard img={cloudit} skills={['ReactJS', 'TailwindCSS']} name="Cloudit" desc="Cloudit is a landing page I developed for a cloud management company in order to better my react skills." link="https://clouditcompany.netlify.app/" />
                        <ProjectCard img={cointrack} skills={['ReactJS', 'Axios', 'API']} name="Cointrack" desc="Cointrack is a website that contains the top 50 cryptocurrencies and it's detailed information." link="https://cryptocointrack.netlify.app/" />
                        <ProjectCard img={weather} skills={['ReactJS', 'Axios', 'API']} name="Weather Daily" desc="Weather Daily is a website where you can find out about the weather in any location in detail." link="https://weather-app-sadcodes.netlify.app/" />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Projects