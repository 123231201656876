import React from 'react'
import HelloImg from '../assets/hello.svg'
import HelloEmoji from '../assets/Hellomoji.svg'

import { motion } from 'framer-motion'

const Hello = () => {

  return (
    <div name="hello" className='w-full h-screen px-2 pt-20 bg-white -z-20'>
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, repeat:Infinity, repeatType: 'reverse' }}
       className='relative w-full '>
        <div className='absolute -top-64 left-0 sm:left-20 md:left-40 lg:left-52 w-72 h-72 md:w-96 md:h-80 rounded-full bg-[#6DFACD] blur-3xl opacity-50 animate-blob '></div>
        <div className=' absolute -bottom-[40rem] -left-60 w-[400px] h-[400px] rounded-full bg-[#FF0088] blur-3xl opacity-50 animate-blob '></div>
        <div className=' absolute top-28 right-0 w-[150px] h-80 md:w-[300px] md:h-[400px] rounded-full bg-[#625EE0] blur-3xl opacity-50'></div>
      </motion.div>
      <div className='h-full max-w-5xl mx-auto text-center'>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <img src={HelloImg} alt='hello' className=' w-72 pt-20 mx-auto sm:pt-10 sm:w-[350px]' />
        </motion.div>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: 'easeOut', delay: 0.5 }}
          className='pt-20 pb-[9.5rem] text-gray-800 sm:pb-[4.5rem] md:pb-[3.2rem] text-4xl sm:text-5xl md:text-6xl leading-tight tracking-[1.15] font-Roobert max-w-4xl mx-auto '>I am <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, ease: 'easeOut', delay: 0.6 }}
            className=' text-gradient bg-gradient-to-br from-indigo-300 to-indigo-700 font-Reckless sm:text-[3.5rem] md:text-[4rem] text-[2.5rem]'>Shashant</motion.span>, i like making fun, Interactive UI Designs.</motion.h1>
        <motion.img
          initial={{ y: 200 }}
          animate={{ y: 0 }}
          transition={{ duration: 1, ease: 'easeOut', delay: 0.9 }}
         src={HelloEmoji} alt='hello' className=' w-72  mx-auto sm:pt-10 sm:w-[350px]' />
      </div>
    </div>
  )
}

export default Hello;