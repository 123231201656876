import React, {useEffect} from 'react'
import AboutText from '../assets/aboutText.svg'
import AboutMoji from '../assets/Aboutmoji.svg'

import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

const About = () => {

  const [ref, inView] = useInView({triggerOnce: true});
  const [ref2, inView2] = useInView({triggerOnce: true});
  const animation = useAnimation();
  const animation2 = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
         opacity:1, transition: { duration: 2 }
      })
    }
    if(!inView){
      animation.start({
       opacity:0
      })
    }
  }, [inView]);

  useEffect(() => {
    if (inView2) {
      animation2.start({
        opacity:1, transition: { duration: 2 }
      })
    }
    if(!inView2){
      animation2.start({
        opacity:0
      })
    }
  }, [inView2]);

  return (
    <div name="about" className='w-full bg-[#121316] py-40 px-4 text-gray-200'>
      <div className='w-full max-w-6xl mx-auto'>
        <motion.div ref={ref} 
          animate={animation}
        className='relative w-full'>
          <img  src={AboutText} alt='about-text' className='mx-auto' />
          <div className='absolute w-full top-4 sm:top-8 md:top-12 lg:top-20'>
            <img src={AboutMoji} alt='about-moji' className='w-64 mx-auto sm:w-72 md:w-80' />
          </div>
        </motion.div>
        <motion.div ref={ref2}
          animate={animation2}
        className='w-full mt-64 text-center'>
          <p className='max-w-3xl mx-auto text-xl text-center lg:text-3xl md:text-2xl font-Roobert'>I'm a student and an aspiring <span className=' bg-gradient-to-r from-[#1de5e2] to-[#b588f7] text-gradient'>Web Developer</span> and Web Designer, looking for the best opportunities to learn new and fascinating things. I am an optimistic and determined person, focused on my clear goal of becoming a prominent Web Developer and contributing to the Community.</p>
        </motion.div>
      </div>
    </div>
  )
}

export default About